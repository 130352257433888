import * as React from "react";
import moment from "moment";
import Link from "../Link/Link";
import Micro from "../../layouts/Micro";
import packageJson from "../../../package.json";
import blog from "../../../data/data-blog.json";

import "./footer.scss";

const Footer = () => {
  const buildDate = Date.now();

  // Helper function to get 5 random unique indexes
  const getRandomIndexes = (max, count) => {
    let randomIndexes = [];
    while (randomIndexes.length < count) {
      const random = Math.floor(Math.random() * max);
      if (randomIndexes.indexOf(random) === -1) {
        randomIndexes.push(random);
      }
    }
    return randomIndexes;
  };

  const randomBlogIndexes = getRandomIndexes(blog.length, 20);
  const randomBlogIndexes2 = getRandomIndexes(blog.length, 20);
  const randomBlogIndexes3 = getRandomIndexes(blog.length, 20);

  return (
    <Micro>
      <footer className="footer micro">
        <div className="container">
          <div className="grid-4">
            <div className="align-vertical">
              <div>
                <img
                  loading="lazy"
                  height="60"
                  src="https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/5f8aec9b-6c07-4d18-a6ff-d77c892bc300/public"
                  alt={`Example Company Inc. v${packageJson.version} build time: ${buildDate}`}
                />

                <div className="padding-top-1rem card-neumorphic-dark padding-1rem">
                  <h6>App Manager</h6>
                  <p>
                    AI IT Agent for Startups: Streamline App and User
                    Provisioning
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h5 className="text-white fw-600">Site</h5>
              <ul className="list-unstyled text-small">
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="/policies/terms"
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="/policies/privacy"
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h5 className="text-white fw-600">AI Guides</h5>
              <ul className="list-unstyled text-small">
                {randomBlogIndexes.map((index) => (
                  <li key={blog[index].slug}>
                    <Link
                      href={blog[index].slug}
                      className="text-white hover-text-yellow"
                    >
                      {blog[index].title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h5 className="text-white fw-600">AI Guides</h5>
              <ul className="list-unstyled text-small">
                {randomBlogIndexes2.map((index) => (
                  <li key={blog[index].slug}>
                    <Link
                      href={blog[index].slug}
                      className="text-white hover-text-yellow"
                    >
                      {blog[index].title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              {/* <h5 className="title">Guides</h5>

              <ul className="list-nav">
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="/how-to-manage-your-remote-team-guide"
                  >
                    How to manage your remote team?
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>

          <hr />

          <div className="grid-3 padding-top-1rem flex flex-middle ">
            <div>
              <p className="padding-1rem">
                ©<strong>2023</strong> All rights reserved.
              </p>
            </div>

            <div className="text-center">
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://twitter.com/appmanagerai"
              >
                <img
                  src="https://res.cloudinary.com/dhikr-zikir-zikirmatik/image/upload/c_scale,q_auto/v1645615289/website/twitter_r1qbd6.svg"
                  alt="App Manager Twitter Official Social Media Account"
                />
              </Link>

              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/appmanagerai/"
              >
                <img
                  src="https://res.cloudinary.com/dhikr-zikir-zikirmatik/image/upload/c_scale,q_auto/v1645615289/website/linkedin_sprcw8.svg"
                  alt="App Manager Linkedin Official Social Media Account"
                />
              </Link>
            </div>

            <div className="text-right">
              Designed in{" "}
              <span role="img" aria-label="Sun">
                ☀️
              </span>{" "}
              California &amp; Built Globally With{" "}
              <span role="img" aria-label="Heart">
                ❤️
              </span>
            </div>
          </div>
          <div>
            <h5 className="text-white fw-600">AI Guides</h5>
            <ul className="list-unstyled text-small">
              {randomBlogIndexes3.map((index) => (
                <li key={blog[index].slug}>
                  <Link
                    href={blog[index].slug}
                    className="text-white hover-text-yellow"
                  >
                    {blog[index].title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </footer>
    </Micro>
  );
};

export default Footer;
