/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import {
  TextAlignJustified,
  Cross,
  ChevronDown,
  ChevronUp,
  ChevronRight,
} from "akar-icons";

import Link from "../Link/Link";
import Micro from "../../layouts/Micro";
import AboutUs from "../../../static/img/menu-icons/about-us.inline.svg";
import Blog from "../../../static/img/menu-icons/blog.inline.svg";

import config from "../../config";

const { logoBlue } = config;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const Menu = () => {
  const [activeDropdown, setActiveDropdown] = useState("none");
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size.width]);

  const setDropdown = (activeDropdownValue) => {
    setActiveDropdown(activeDropdownValue);
  };

  const setHamburgerStatus = (hamburgerStatus) => {
    setHamburgerOpen(hamburgerStatus);
  };

  const handleLinkClick = () => {
    setDropdown("remote");
    if (isMobile) {
      setHamburgerStatus(false);
    }
    document.body.style.overflow = "auto";
  };

  return (
    <Micro>
      <header className="remote-header">
        <div className="menu-container">
          <div className="navigation">
            <figure className="logo">
              <Link onClick={handleLinkClick} href="/" title="App Manager">
                <img loading="lazy" src={logoBlue} alt="AppManager" />
              </Link>
            </figure>

            <menu className="menu" role="navigation">
              <Hamburger
                className="responsive-toggle"
                setHamburgerStatus={setHamburgerStatus}
                hamburgerOpen={hamburgerOpen}
              >
                <ul className="main-menu">
                  <li>
                    <span>
                      <Link className="menu-link" href="/">
                        Home
                      </Link>
                    </span>
                  </li>

                  <li>
                    <span>
                      <Link className="menu-link" href="/blog">
                        Blog
                      </Link>
                    </span>
                  </li>
                </ul>
              </Hamburger>
            </menu>
            <Link
              className="demo-tour-button"
              href="https://calendly.com/appmanager-demo/30min"
              target="_blank"
              style={{ color: "white" }}
            >
              Schedule a demo
            </Link>
          </div>
        </div>
      </header>
    </Micro>
  );
};

const DropDown = (props) => {
  const { title, activeDropdown, setDropdown, isMobile, children } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (title && activeDropdown === title) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [activeDropdown, title]);

  return (
    <div
      onMouseLeave={() => {
        if (!isMobile) {
          setTimeout(setDropdown("close"), 1000);
        }

        document.body.style.overflow = "auto";
      }}
      onMouseEnter={() => !isMobile && setDropdown(title)}
      role="button"
      tabIndex={0}
    >
      <Link
        className="menu-link"
        onClick={() => {
          if (open) {
            if (isMobile) {
              setDropdown("close");
            }
            document.body.style.overflow = "auto";
          } else {
            if (isMobile) {
              setDropdown(title);
            }
            document.body.style.overflow = "scroll";
          }
        }}
      >
        <span>{title}</span>
        {open ? (
          <ChevronUp
            strokeWidth={4}
            size={20}
            style={{ paddingBottom: "-1rem", paddingTop: "0.6rem" }}
          />
        ) : (
          <ChevronDown
            strokeWidth={4}
            size={20}
            style={{ paddingBottom: "-1rem", paddingTop: "0.6rem" }}
          />
        )}
      </Link>
      <div style={{ display: activeDropdown === title ? "block" : "none" }}>
        {children}
      </div>
    </div>
  );
};

const Hamburger = (props) => {
  const { children, hamburgerOpen, setHamburgerStatus } = props;
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size]);

  useEffect(() => {
    if (!isMobile) {
      setHamburgerStatus(true);
    } else {
      setHamburgerStatus(false);
      document.body.style.overflow = "auto";
    }
  }, [isMobile]);

  return (
    <div role="button" tabIndex={0}>
      {isMobile && (
        <Link
          onClick={() => {
            if (hamburgerOpen) {
              setHamburgerStatus(false);
              document.body.style.overflow = "auto";
            } else {
              setHamburgerStatus(true);
              document.body.style.overflow = "hidden";
            }
          }}
        >
          {hamburgerOpen ? (
            <Cross strokeWidth={2} size={28} style={{ margin: "0.5rem" }} />
          ) : (
            <TextAlignJustified
              strokeWidth={2}
              size={28}
              style={{ margin: "0.5rem 0 0.5rem 0.7rem" }}
            />
          )}
        </Link>
      )}
      <div
        style={{
          display: hamburgerOpen ? "block" : "none",
          margin: "none",
          padding: "none",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Menu;
