// using module.exports here so gatsby-config can read it

const siteUrlDefault = "https://www.appmanager.ai";

const config = {
  title: "AI IT Agents for Startups | AppManager",
  titleTemplate: "%s",
  siteUrl: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  lealTitle: "AI IT Agents for Startups | AppManager",
  description:
    "With the power of AI, we make app and user provisioning affordable and effortless. Streamline user and app provisioning now!",
  url: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  image:
    "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/42ed5de4-988e-4f0f-c2f4-8fe7b6d42500/public", // Path to your image you placed in the 'static' folder
  logoBlue:
    "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/42ed5de4-988e-4f0f-c2f4-8fe7b6d42500/public",
  postThumbnail:
    "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/42ed5de4-988e-4f0f-c2f4-8fe7b6d42500/public",
  twitterUsername: "appmanagerai",
  blogDesktopBanner:
    "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/42ed5de4-988e-4f0f-c2f4-8fe7b6d42500/public",
  blogMobileBanner:
    "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/42ed5de4-988e-4f0f-c2f4-8fe7b6d42500/public",
  airtableTables: [
    "Blog",
    //"programatic-apps",
    //"programatic-apps-2",
    //"programatic-apps-3",
    // "programatic-apps-4",
    // "programatic-apps-5",
    //"programatic-apps-6",
    //"programatic-apps-7",
    //"programatic-apps-8",
    //"programatic-apps-9",
    //"programatic-apps-10",
    "app-pages",
  ],
  mainColor: "#4B8EF9",
  // those fields are required for the page generation process.
  airtableSpecialFields: [
    "slug",
    "title",
    "description",
    "headline",
    "published",
    "shareOnTwitter",
    "imageUrl",
    "shareOnWhatsapp",
    "shareOnSms",
  ],
};

module.exports = config;
