/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React from "react";
import { builder, BuilderComponent } from "@builder.io/react";
import kebabCase from "kebab-case";
import Link from "../components/Link/Link";
import SEO from "../components/SEO/SEO";

builder.init(process.env.BUILDER_API_KEY);

const Template = ({ pageContext }) => {
  const { tableName, airtableData, builderDesign } = pageContext;

  return (
    <>
      {airtableData && (
        <SEO
          title={airtableData.title}
          description={airtableData.description}
        />
      )}

      <article className="builder-blocks">
        {builderDesign && airtableData && (
          <BuilderComponent
            renderLink={Link}
            name={kebabCase(tableName)}
            model={tableName}
            content={builderDesign}
            data={airtableData}
          />
        )}
      </article>
    </>
  );
};

export default Template;
